<template>
  <LayoutBox :loading="loading" title="预警变化趋势">
    <div slot="layoutBox-right" style="width: 120px">
      <Select
        v-model="queryParam.trendDateType"
        box-shadow
        :allow-clear="false"
        custom
        :options="trendDate"
        :replace-fields="{
          lable: 'msg',
          value: 'code',
        }"
        placeholder="请选择周期"
      ></Select>
    </div>
    <div ref="areaRef" style="height: 100%"></div>
  </LayoutBox>
</template>

<script>
import { mapState } from 'vuex';
import LayoutBox from '@/views/screen/components/LayoutBox.vue';
import Select from '@/views/screen/components/Select.vue';
import { Area } from '@antv/g2plot';
export default {
  //组件注册
  components: { LayoutBox, Select },
  //组件传值
  props: {
    prefix: {
      type: String,
      default: 'staff',
    },
    globalQuery: {
      type: Object,
      default: () => ({}),
    },
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      queryParam: {},
      replaceFields: {
        xField: 'text',
        yField: 'count',
      },
      areaChart: null,
    };
  },
  //计算属性
  computed: {
    ...mapState({
      trendDate: (state) =>
        state.screenStore.commondict['MonitorTrendDateTypeEnum']
          ? state.screenStore.commondict['MonitorTrendDateTypeEnum']
          : [],
    }),
  },
  //属性监听
  watch: {
    queryParam: {
      deep: true,
      handler: 'getData',
    },
    globalQuery: {
      deep: true,
      handler: 'getData',
    },
    refreshKey: {
      handler: 'getData',
    },
  },
  //DOM访问
  mounted() {
    this.areaChart = new Area(this.$refs.areaRef, {
      appendPadding: [8, 0, 0, 0],
      color: '#25B5FF',
      areaStyle: {
        fill: 'l(270) 0:#022F5D 1:#25B5FF',
      },
      smooth: true,
      data: [],
      ...this.replaceFields,
      xAxis: {
        label: {
          style: {
            fill: '#DEF8FF',
          },
        },
        tickLine: null,
        line: {
          style: {
            stroke: '#20579B',
            lineWidth: 1,
            opacity: 0.7,
            lineDash: [4, 5],
          },
        },
      },
      yAxis: {
        label: {
          style: {
            fill: '#DEF8FF',
          },
        },
        grid: {
          line: {
            style: {
              stroke: '#20579B',
              lineWidth: 1,
              opacity: 0.7,
              lineDash: [4, 5],
            },
          },
        },
      },
      tooltip: {
        showTitle: false,
        formatter: (column) => ({
          name: column[this.replaceFields.xField],
          value: `${column[this.replaceFields.yField]}`,
        }),
      },
    });
    this.areaChart.render();
    this.queryParam = {
      trendDateType: this.trendDate[0].code,
    };
    this.$bus.$on('global-refresh', this.getData);
  },
  //保存方法
  methods: {
    async getData() {
      try {
        this.loading = true;
        const data = await this.$http.get(
          `/${this.$businessISG}/monitor/${this.prefix}/warningtrend-stat`,
          { ...this.globalQuery, ...this.queryParam }
        );
        if (data.code != 200) throw data.message;
        if (!data.data) throw '数据格式错误！';
        this.areaChart.changeData(data.data);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
