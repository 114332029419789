<template>
  <div class="layoutBox boxShadow">
    <div
      :style="`display: flex; justify-content: space-between; align-items: center;padding-bottom: 6px;border-bottom: 3px solid;border-image: url(${require('@/assets/screen/bj/line.png')}) 3 3 round;`"
    >
      <div
        style="display: flex; align-items: center; flex: 1; margin-right: 8px"
      >
        <img
          src="@/assets/screen/bj/img_title.png"
          width="8"
          height="18"
          alt="img_title"
        />
        <div
          style="
            font-size: 20px;
            font-weight: bold;
            color: #ffffff;
            margin-left: 8px;
          "
        >
          {{ title }}
        </div>
      </div>
      <div>
        <slot name="layoutBox-right"></slot>
      </div>
    </div>
    <div style="position: relative; flex: 1; overflow: hidden">
      <slot></slot>
      <v-overlay :opacity="1" absolute :value="loading">
        <v-progress-circular
          indeterminate
          color="#25E3FF"
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>

<script>
export default {
  //组件注册
  components: {},
  //组件传值
  props: {
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  //计算属性
  computed: {},
  //属性监听
  watch: {},
  //DOM访问
  mounted() {},
  //保存方法
  methods: {},
};
</script>

<style lang="scss" scoped>
.layoutBox {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(1, 21, 44, 0.3);
  border: 1px solid;
  border-image: linear-gradient(-36deg, #00f0ff, #22c3ff) 1 1;
  padding: 12px 16px;
  overflow: hidden;
  &::after,
  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-right: 6px solid #25e3ff;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
    z-index: 1;
  }
  &::after {
    left: -6px;
    bottom: -7px;
    transform: rotate(-45deg) scale(1.5);
  }
  &::before {
    right: -7px;
    bottom: -7px;
    transform: rotate(-140deg) scale(1.6);
  }
}
</style>
